.pillers-head-contt {
    padding-left: 5%;
    margin-bottom: 20px;
    margin-top: 50px;
}

.pillar-heading {
    /* font-family: 'Reddit Sans'; */
    font-size: 32px;
    font-weight: 700;
    line-height: 41.56px;
    text-align: left;
    color: #032168;
    margin: 0 !important;
}

.pillar-sub-heading {
    /* font-family: 'Reddit Sans'; */
    font-size: 20px;
    font-weight: 400;
    line-height: 25.98px;
    text-align: left;
}

.pillar-card-heading {
    /* font-family: 'Reddit Sans'; */
    font-size: 20px;
    font-weight: 700;
    line-height: 25.98px;
    text-align: left;
    color: #000000;
}

.pillar-primary-container .pillar-card-sub-heading {
    /* font-family: 'Reddit Sans'; */
    font-size: 16px;
    font-weight: 400;
    line-height: 20.78px;
    text-align: left;
    color: #000000;
}

.pillar-primary-container .pillar-card-div {
    padding: 7px 17px;
    width: 31%;
    /* height: 149px; */
    top: 990px;
    left: 60px;
    gap: 0px;
    border-radius: 12px;
    background: linear-gradient(270.39deg, #CD9E0B -5.4%, #FFCD31 110.61%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}

.pillar-primary-container .pillar-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 5%;

}