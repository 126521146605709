.gold-market {
    background-image: url("../../../../../assets/images/goldmarket.jpg");
    width: 100%;
    margin-top: 10rem;
    background-size: cover;
}


.golbal-text {
    font-family: 'Reddit Sans';
    font-size: 40px;
    font-weight: 700;
    line-height: 51.95px;
    text-align: left;
    text-underline-position: ' from-font';
    text-decoration-skip-ink: none;
    color: #262424;


}

.explore-gold {
    font-family: 'Reddit Sans';
    font-size: 20px;
    font-weight: 400;
    line-height: 25.98px;
    text-align: center;
    text-underline-position: 'from-font';
    text-decoration-skip-ink: none;
    color: #262424;

}

.geo-text {
    font-family: 'Reddit Sans';
    font-size: 32px;
    font-weight: 700;
    line-height: 41.56px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: black;

}

.geo-subtext {
    font-family: 'Reddit Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 20.78px;
    letter-spacing: 0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: black;
}

.bg-cream {
    background: #FFF7ED;
    width: 100%;
    padding: 40px;
}

.bg-gray {
    width: 100%;
    background: #F5F9FF;
    margin-top: 5rem;
    padding: 40px;

}

.interest-text {
    font-family: 'Reddit Sans';
    font-size: 20px;
    font-weight: 600;
    line-height: 25.98px;
    letter-spacing: 0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 5px;
    border-radius: 4px;
}

.intrest-color {
    background: #FFC719;

}

.price-color {
    background: #093482;

}

.vs {
    font-family: 'Reddit Sans';
    font-size: 20px;
    font-weight: 400;
    line-height: 25.98px;
    letter-spacing: 0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #757575;


}

.gap-15 {
    gap: 15px;
}

.pie-heading {
    font-family: 'Reddit Sans';
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: justified;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: black;

}

.pie-subheading {
    font-family: Reddit Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: justified;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: black;
}

.pie-main {
    font-family: Reddit Sans;
    font-size: 32px;
    font-weight: 700;
    line-height: 41.56px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #032168
}

.pie-container {
    background-image: url('../../../../../assets/images/piechart.jpg');
    background-size: cover;
    /* Scales the image to cover the element */
    background-position: center;
    /* Centers the image */
    background-repeat: no-repeat;
    height: 454px;
    width: 80%;
}

.pie-div {
    justify-content: center;
    padding: 2rem;
    background-color: #F5F9FF;
    width: 100%;
    margin-top: 4rem;
}


.cfd-container {
    width: 100%;
    height: 645px;
    background-color: beige;
    border-radius: 24px;
    margin-top: 4rem;
}

.top-gold-text {
    font-family: 'Reddit Sans';
    font-size: 32px;
    font-weight: 700;
    line-height: 41.56px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #032168;

}

.marginT-4 {
    margin-top: 4rem;
}

.future-text {
    font-family: 'Reddit Sans';
    font-size: 32px;
    font-weight: 700;
    line-height: 41.56px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: white
}

.future-subtext {
    font-family: 'Reddit Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 20.78px;
    letter-spacing: 0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: white
}

.demand-subtext {
    font-family: 'Reddit Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 20.78px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: white;

}

.demand-text {
    font-family: 'Reddit Sans';
    font-size: 20px;
    font-weight: 700;
    line-height: 25.98px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: white;

}

.future-container {
    background-color: #093482;
    position: relative;
    height: 843px;

}

.dimg {
    position: absolute;
    right: 2px;
    top: 8rem;

}

.star-div {
    margin-left: 2rem;
}

.world-text {
    font-family: 'Reddit Sans';
    font-size: 32px;
    font-weight: 700;
    line-height: 41.56px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #032168;

}

.world-subtext {
    font-family: 'Reddit Sans';
    font-size: 24px;
    font-weight: 700;
    line-height: 31.17px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: black;
}

.country-container {
    margin-bottom: 4rem;
}

.border-radius-3 {
    border-radius: 32px;
}

.currency-text {
    font-family: Reddit Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 31.17px;
    letter-spacing: 0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: black;

}

.gold-ball {
    mix-blend-mode: darken;
}