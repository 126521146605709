.footer-container {
    background-color: #093280;
    display: flex;
    height: 296px;
    justify-content: space-between;
    padding: 50px;
}

.footer-sub-container-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 35%;
    padding: 20px;
}

.footer-sub-container-two {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 25%;
    padding: 20px;
    gap: 25px;

}

.footer-sub-container-three {
    width: 40%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.footer-logo {
    height: 100px;
    width: 150px;
    cursor: pointer;
}

.f-logo-img {
    height: 100%;
    width: 100%;
}

.fsco-one {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #FFFFFF;

}

.fsco-two {
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
}

.navigator-a {
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    width: 50%;
    cursor: pointer;
}

.fsct-content {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
}

.sm-container {
    width: 40%;
    display: flex;
    justify-content: space-between;
}

.header-sc {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.header-sub-container {
    display: flex;
    width: 50%;
    gap: 15px;
    align-items: center;
}

.header-label {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
}