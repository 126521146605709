.about-mobile-carousel-container {
    height: 440px;
    margin-top: 80px;
    margin-bottom: 30px;
    position: relative;
}

.about-car-content {
    position: absolute;
    top: 0;
    padding: 20px;
    width: 90%;
}

.about-title-one {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    color: #FFDD00;
    display: flex;
    align-items: center;
    gap: 10px;

}

.mob-about-title-two {
    font-family: Reddit Sans;
    font-size: 36px;
    font-weight: 700;
    color: #FFFFFF;
    margin-top: 10px;
}

.mob-about-title-three {
    font-family: Reddit Sans;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 10px;
}

.mos-text {
    font-family: Reddit Sans;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    width: 90%;
    margin-top: 30px;
    text-align: justify;
    margin-left: 5%;
    margin-right: 5%;
}

.mos-img {
    margin-top: 70px;
}

.mob-our-story {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mob-our-mission-container {
    height: 1240px;
    background-color: #153C97;
    margin-top: 30px;
    padding: 20px;
}

.mob-om-head {
    font-family: "Reddit Sans";
    font-size: 32px;
    font-weight: 700;
    color: #FFFFFF;
    margin-top: 10px;
    margin-left: 5%;
    margin-right: 5%;
}

.mob-om-text {
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 20px;
    text-align: justify;
}

.mob-om-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    margin-top: 30px;
}

.mob-om-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.moc-image {
    height: 70px;
    width: 70px;
    border-radius: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.moc-text-one {
    font-family: Reddit Sans;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
}

.moc-text {
    font-family: Reddit Sans;
    font-size: 14px;
    font-weight: 500;
    color: #DEDEDE;
    text-align: center;
}

.mob-gold-mining {
    height: 650px;
    margin-top: 30px;
}

.mtc-one {
    font-family: "Reddit Sans";
    font-size: 32px;
    font-weight: 700;
    color: #032168;
    margin-top: 30px;
}

.mtc-two {
    font-family: "Reddit Sans";
    font-size: 16px;
    font-weight: 400;
    color: #232323;
    margin-top: 10px;
    text-align: justify;
}

.mgm-img {
    width: 100%;
}

.mgm-text-container {
    padding: 20px;
}

.mob-fkb-container {
    height: 1550px;
    margin-top: 70px;
    background-color: #F4F7FB;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.mfkb-head {
    font-family: "Reddit Sans";
    font-size: 32px;
    font-weight: 700;
    color: #032168;
    width: 48%;
}

.fkb-mob-image {
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.mob-fkb-card {
    width: 90%;
    height: 435px;
    border-radius: 16px;
    background-color: #FFFFFF;
    margin-left: 5%;
    margin-right: 5%;
}

.mfcc-head {
    font-family: "Reddit Sans";
    font-size: 20px;
    font-weight: 700;
    color: #000000;
}

.mfcc-text {
    font-family: "Reddit Sans";
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin-top: 10px;
    text-align: justify;
}

.mob-fkb-card-content {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 2%;
    padding-right: 2%;
}

.mkc-img {
    width: 100%;
}

.mob-kmu-container {
    margin-top: 30px;
}

.mob-core-assets-container {
    height: 459px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    /* background-color: #F7F9FD; */
    justify-content: space-evenly;

}

.mob-ca-head {
    font-family: "Reddit Sans";
    font-size: 32px;
    font-weight: 700;
    color: #032168;
}

.mca-text {
    font-family: "Reddit Sans";
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    width: 90%;
    text-align: center;
}

.mob-ana-container {
    width: 100%;
    background-color: #153C97;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
}

.mob-ana-header {
    font-size: 32px;
    font-weight: 700;
    height: 60px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: flex-start;
    width: 60%;
}

.mob-ana-award {
    display: flex;
    align-items: flex-start;
    margin-top: 50px;
}

.mob-ana-award-icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}


.mob-ana-award-content {
    flex-grow: 1;
}

.mob-ana-award-title {
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: 700px;
    color: #FFFFFF;

}

.mob-ana-award-description {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    text-align: justify;
    padding-right: 1%;
    padding-left: 1%;
}

.mob-ana-star {
    width: 25px;
}

.mob-ana-award-icon {
    width: 35px;
}

.mob-mep-container {
    margin-top: 30px;
}