.m-navbar-conainer{
height: 80px;
background-image: linear-gradient(90deg , #00185C , #1458B5) ;
display: flex;
justify-content: space-between;
padding: 20px;
align-items: center;
position: fixed;
top: 0;
z-index: 1;
width: 100%;
}

.button-fav-container{
height: 40px;
width: 40px;
background-color: #ffffff;
display: flex;
justify-content: center;
align-items: center;
border-radius: 10px;
}

.drawer-container-mobile{
display: flex;
flex-direction: column;
gap: 80px;
align-items: flex-end;
padding: 35px;
}

.link-mobile-container{
display: flex;
flex-direction: column;
align-items: center;
gap: 30px;
width: 100%;
}

.link-mobile-nav{
color: #ffffff;
text-decoration: none;
font-size: 24px;
font-weight: 600;
}

.close-drawer-btn{

}