.mobile-footer-container {
    height: 750px;
    background-color: #093280;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.mobile-gds-logo-container {
    height: 100px;
    width: 130px;
}

.mob-gds-img {
    height: 100%;
    width: 100%;
}

.mobile-footer-text-one {
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    margin-top: 25px;
}

.mobile-footer-text-two {
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 400;
    color: #E3E3E3;
    width: 85%;
    text-align: center;
    margin-top: 15px;

}

.mob-footer-link-container {
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    margin-top: 10px;

}

.mobile-footer-link {
    font-family: "Reddit Sans";
    font-size: 16px;
    font-weight: 600;
    color: #DCDCDC;
}

.margin-div {
    border-bottom: 1px solid #ffffff;
    width: 80%;

}