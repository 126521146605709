.gallery-main-container {
    margin-top: 110px;
}

.gallery-first-container {
    height: 486px;
    display: flex;
}

.km-container {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 3%;
    justify-content: center;
}

.marquee-container {
    width: 70%;
}

.km-text-head {
    font-family: Reddit Sans;
    font-size: 48px;
    font-weight: 700;
    color: #032168;
}
.km-text-body {
    text-align: justify;
}

.km-text {
    font-family: Reddit Sans;
    font-size: 20px;
    font-weight: 500;
    color: #323232;
}

.multi-img-contt {
    height: 100%;
    margin-top: 30px;
    margin: 2%;
}

.multi_image_container {
    border: 2px solid rgb(237, 237, 237);
    padding: 20px;
    border-radius: 32px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 40px;
}
.multi_image_container img{
    width: 100%;
    height: 350px;
}

.multi-image {
    height: 100%;
    width: 100%;
}

.second-marquee-contt {
    height: 372px;
    margin-top: 30px;
}

.som-img-contt {
    padding: 2%;
}

.som-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
}

.som-text {
    font-family: Reddit Sans;
    font-size: 32px;
    font-weight: 700;
    color: #093280;
    text-align: center;
}

.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.marquee div {
  animation: scroll 12s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}


.mq-sc{
    display: flex;
    gap: 20px;
    margin-top: 5.5%;
}

.mq-scc{
    display: flex;
    gap: 20px;
}