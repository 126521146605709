.parent-about-us-container {


    .margin-div {
        margin-top: 80px;
    }

    .about-carousel-container {
        position: relative;
        height: 440px;
        width: 100%;
    }



    .carousel-inner img {
        height: 440px;
        object-fit: cover;

    }

    .carousel-content {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        gap: 60px;
        margin-top: 20px;
        margin-left: 40px;

    }


    .bread-crumbs {
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: #FFFFFF;
    }

    .bread-crumbs-a {
        color: #FFFFFF;
        text-decoration: underline;
    }

    .about-title-one {
        font-size: 20px;
        font-weight: 700;
        text-align: left;
        color: #FFDD00;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .about-title-two {
        font-size: 64px;
        font-weight: 700;
        text-align: left;
        color: #FFFFFF;

    }

    .about-title-three {
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        color: #FFFFFF;
    }

    .our-story-container {
        height: 331px;
        background-image: url("../../../../assets/images/our-story-img.jpg");
        background-repeat: no-repeat;
        margin-top: 50px;
        display: flex;
        justify-content: end;
        align-items: end;
    }

    .osc-sub-container {
        height: 70%;
        width: 67%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .os-text {
        font-size: 32px;
        font-weight: 700;
        text-align: left;
        color: #032168;
    }

    .os-text-two {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        text-align: justify;
        padding-right: 60px;
    }

    .our-vision-container {
        background-color: #F7F9FD;
        /* height: 470px; */
        padding: 40px;
        padding-left: 7%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .ovc-sub-container {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: center;
        align-items: center;

    }

    .ovcsc-sub-card-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .ocv-card {
        width: 26%;
        height: 140px;
    }

    .our-vision-head {
        font-size: 32px;
        font-weight: 700;
        text-align: left;
        color: #032168;
    }

    .ovc-card-heading {
        font-size: 16px;
        font-weight: 800;
        line-height: 28px;
        color: #EFB803;
    }

    .ovc-content {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        color: #000000;
        text-align: justify;
    }

    .our-mission-container {
        background-color: #153C97;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 50px;
        margin-top: 50px;
    }

    .our-mission-heading {
        font-size: 32px;
        font-weight: 700;
        text-align: left;
        color: #FFFFFF;
    }

    .om-container-title {
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: #FFFFFF;

    }

    .omc-icon {
        width: 70px;
        height: 70px;
        background-color: white;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .omc-heading {
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        color: #FFFFFF;
    }

    .omc-content {
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        text-align: justify;
    }

    .om-card {
        width: 22%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .om-card-container {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .omc-card-sub-container {
        display: flex;
        justify-content: space-between;
    }

    .gold-mining-pic {
        width: 408px;
    }

    .gm-sub-container {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
    }

    .gold-mining-container {
        display: flex;
        /* justify-content: space-evenly; */
        background-color: #F4F7FB;
        gap: 100px;
        padding: 80px;
        margin-top: 50px;
    }

    .gmc-head {
        font-size: 36px;
        font-weight: 700;
        text-align: left;
        color: #032168;
    }

    .gmc-content {
        font-size: 24px;
        font-weight: 400;
        color: #232323;
        text-align: justify;
    }

    .gm-img {
        height: 100%;
        width: 100%;
    }

    .fkb-container {
        background-color: #F4F7FB;
        height: 570px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: 50px;
    }

    .fkb-main-heading {
        font-size: 36px;
        font-weight: 700;
        text-align: left;
        color: #032168;
        padding-left: 2%;

    }

    .fkb-card {
        width: 450px;
        height: 411px;
        background-color: #FFFFFF;
        border-radius: 16px;
    }

    .fkb-card-img {
        height: 60%;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
    }

    .fkb-card-container {
        display: flex;
        justify-content: space-evenly;
    }

    .fkb-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .fkb-image {
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        width: 100%;
    }

    .fkb-card-content-container {
        height: 35%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 15px;
        text-align: justify;
    }

    .fkb-card-heading {
        font-size: 20px;
        font-weight: 700;
        color: #000000;
    }

    .fkb-card-content {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
    }

    .milestone-containe {
        background-color: #032168;
        background-image: url("../../../../assets/images/milestone-bg.jpg");
        background-repeat: no-repeat;
        padding-top: 10px;
        padding-bottom: 46px;
        margin-top: 50px;
    }


    .milestone-heading {
        /* font-family: 'Reddit Sans'; */
        font-size: 32px;
        font-weight: 700;
        line-height: 41.56px;
        text-align: left;
        color: white;
        padding: 0px 7.2%;
    }

    .milestone-card-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .expertise-heading {
        /* font-family: 'Reddit Sans'; */
        font-size: 20px;
        font-weight: 500;
        line-height: 25.98px;
        text-align: left;
        margin: 0;
        color: white;
    }

    .expertise-heading-1 {
        /* font-family: 'Reddit Sans'; */
        font-size: 96px;
        font-weight: 700;
        line-height: 124.69px;
        text-align: left;
        margin: 0;
        color: white;
    }

    .expertise-heading-2 {
        /* font-family: "Reddit Sans"; */
        font-size: 15px;
        font-weight: 400;
        line-height: 19.48px;
        text-align: left;
        margin: 0;
        color: #D4D4D4;
    }

    .kmu-container {
        width: 100%;
        margin-top: 50px;

    }

    .kmu-image {
        width: 100%;
    }

    .core-assets-container {
        background-image: url("../../../../assets//images/core-assets.jpg");
        margin-top: 50px;
        display: flex;
        justify-content: end;
        align-items: end;
        height: 259px;
        background-repeat: no-repeat;
        background-color: #F7F9FD;

    }

    .ca-content-container {
        width: 65%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        height: 100%;
    }

    .ca-content-heading {
        font-size: 32px;
        font-weight: 700;
        color: #032168;
    }

    .ca-content {
        font-size: 20px;
        font-weight: 500;
        color: #000000;
    }

    .ana-main-sub-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 90%;
        gap: 20px;
    }

    .amsc-one {
        display: flex;
        justify-content: space-evenly;
    }

    .amsc-two {
        display: flex;
        justify-content: space-evenly;
    }



    .ana-main-container {
        height: 503px;
        background-color: #093280;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 50px;
    }

    .ana-card {
        width: 375px;
        /* height: 125px; */
        display: flex;
        gap: 10px;
        justify-content: start;
    }

    .ana-icon {
        margin-top: 5px;
    }

    .ana-card-heading {
        font-size: 20px;
        font-weight: 700;
        color: #FFFFFF;

    }

    .ana-card-content {
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        text-align: justify;
    }

    .ana-main-heading {
        font-size: 32px;
        font-weight: 700;
        color: #FFFFFF;

    }

    .star-container {
        display: flex;
        gap: 10px;
        justify-content: start;
        align-items: center;
        padding-left: 6%;
        padding-top: 25px;
    }

    .ana-card-sub-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .mep-container {
        width: 100%;
        margin-top: 50px;
    }

    .mep-image {
        width: 100%;
    }

    .margin-container {
        margin-top: 50px;
    }

    .about-carousel-container .car-sc-two {
        width: 100%;
    }


}

.ato-span {
    width: 153px;
    border: none;
    height: 2px;
    background-image: linear-gradient(90deg, #FFC400 , #FFC40000);
}