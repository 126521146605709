* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* @font-face {
    font-family: 'Reddit Sans';
    src: url('assets/fonts/reddit-sans.woff2') format('woff2'),
         url('assets/fonts/reddit-sans.woff') format('woff'),
         url('assets/fonts/reddit-sans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'Reddit Sans';
} */


.carousel-background {
    position: relative;
    width: 100%;
    height: 810px;
    overflow: hidden;
}

.carousel-contt {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* Send carousel behind the content */
}

.carousel-images {
    display: flex;
    width: 300%;
    height: 100%;
    animation: slide 24s infinite;
}

.slide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

@keyframes slide {
    0% {
        transform: translateX(0%);
    }

    33.33% {
        transform: translateX(0%);
    }

    33.34% {
        transform: translateX(-100%);
    }

    66.66% {
        transform: translateX(-100%);
    }

    66.67% {
        transform: translateX(-200%);
    }

    100% {
        transform: translateX(-200%);
    }
}

.content {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 20px;
    height: 100%;
}

.carousel-sub-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.car-sc-one {
    width: 60%;

}

.car-sc-two {
    width: 380px;
    height: 380px;
    border-radius: 5px;
}

.car-heading-title {
    /* font-family: Reddit Sans; */
    font-size: 48px;
    font-weight: 700;
    text-align: left;
}

.car-content {
    /* font-family: Reddit Sans; */
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #222222;
}

.read-more-btn {
    background-image: linear-gradient(90deg, #E7CD12, #C48E08);
    border: none;
    height: 51px;
    width: 196px;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    /* Optional: Adds a pointer cursor for buttons */
    font-size: 16px;
    /* Optional: Adjusts text size */
    text-align: center;
    /* Optional: Ensures text is centered */
    line-height: 51px;
    /* Optional: Vertically centers text */
}


.car-one-contt {
    display: flex;
    flex-direction: column;
    gap: 25px;
}


.milestone-heading {
    font-family: 'Reddit Sans';
    font-size: 32px;
    font-weight: 700;
    line-height: 41.56px;
    text-align: left;
    color: white;
    padding: 0px 7.2%;
}

.expertise-heading {
    font-family: 'Reddit Sans';
    font-size: 20px;
    font-weight: 500;
    line-height: 25.98px;
    text-align: left;
    margin: 0;
    color: white;
}

.expertise-heading-1 {
    /* font-family: 'Reddit Sans'; */
    font-size: 96px;
    font-weight: 700;
    line-height: 124.69px;
    text-align: left;
    margin: 0;
    color: white;
}

.expertise-heading-2 {
    /* font-family: "Reddit Sans"; */
    font-size: 15px;
    font-weight: 400;
    line-height: 19.48px;
    text-align: left;
    margin: 0;
    color: #D4D4D4;
}

.milestone-containe {
    background-color: #032168;
    background-image: url("../../../../assets/images/milestone-bg.jpg");
    background-repeat: no-repeat;
    padding-top: 10px;
    padding-bottom: 46px;
    margin-top: 50px;
}

.milestone-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.ch-new-title {
    color: #0A3584;
    /* font-family: Reddit Sans; */
    font-size: 20px;
    font-weight: 700;
    text-align: left;

}

.mining-container {
    height: 560px;
    background-color: #F4F7FB;
    display: flex;
    margin-top: 50px;
    padding: 2%;
    align-items: center;
    position: relative;
}

.mining-design-container {
    width: 35%;
    height: 95%;
    display: flex;
    justify-content: space-between;
    position: relative;
    /* Add this to make it the parent for absolute positioning */
}

.mining-design-image {
    height: 113px;
    width: 113px;
}

.mining-design-imageee {
    height: 113px;
    width: 113px;
    z-index: 11111;
    position: relative;
}



.new-contt {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mining-carousel {
    width: 72%;
    /* Adjust width to take full width of the container */
    height: 72%;
    /* Adjust height to take full height of the container */
    border-radius: 5px;
    position: absolute;
    /* To position it within the container */
    top: 40px;
    /* Align to the top */
    left: 70px;
    /* Align to the left */
}


.mining-carousel .carousel-indicators {
    bottom: -100px !important;

}


.img-mining {
    height: 100%;
    width: 100%;
}

.img-mininggg {
    height: 100%;
    width: 80%;
    padding: 40%;
}

.mining-heading {
    color: #032168;
    font-size: 36px;
    font-weight: 700;
    text-align: left;
}

.mining-content {
    color: #232323;
    font-size: 24px;
    font-weight: 400;
    text-align: justify;

}

.mining-card-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 2%;
    width: 60%;
}

.our-core-values {
    width: 100%;
    height: 700px;
}

.ocv-img {
    width: 100%;
    object-fit: contain;
}

.current-project-container {
    background-image: url("../../../../assets/images/current-project-bg.png");
    height: 508px;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    background-position: center;
}


.cpc-heading {
    font-size: 32px;
    font-weight: 700;
    text-align: left;
    color: #032168;
}

.cpc-content {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    columns: #545454;
    text-align: justify;
}

.current-project-sub-container {
    width: 60%;
    display: flex;
    justify-content: left;
    flex-direction: column;
    gap: 25px;
    justify-content: center;
    height: 100%;
    margin: 3%;
}

.ocv-primary-container {
    height: 993px;
    background-image: url("../../../../assets/images/ocv-bg.jpg");
    background-size: cover;
    background-position: center;
    padding: 5%;
margin-top: 50px;
}

.ocv-main-container {
    display: flex;
    flex-direction: column;
    height: 90%;
    justify-content: space-between;
    margin-top: 20px;

}

.omc-sub-contt {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.ocv-cont-1 {
    width: 390px;
    height: 137px;
    border-radius: 16px;
    background-image: linear-gradient(90deg, #093280, #4C6BB1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}

.ocv-cont-2 {
    width: 390px;
    height: 137px;
    border-radius: 16px;
    background-image: linear-gradient(90deg, #CC9C0B, #E3B21B);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}

.ocv-heading {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    color: #032168;
    height: 50px;
}

.ocv-card-heading {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    color: #FFFFFF;
}

.ocv-card-text {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #FFFFFF;
    text-align: justify;
    text-justify: inter-word;
}