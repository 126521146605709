.current-project-bg {
    height: 200px;
    display: flex;
    justify-content: center;
    background-image: url("../../../../assets/images/cp-frame.jpg");
    background-repeat: no-repeat;
    margin-top: 60px;
    margin-left: 11%;
    margin-right: 11%;
}

.cp-title {
    font-family: Reddit Sans;
    font-size: 48px;
    font-weight: 700;
    color: #032168;
    margin-top: 110px;
}

.gm-container {
    height: 473px;
    background-color: #F7F9FD;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 40px;
}

.location-container {
    height: 590px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 40px;
}

.gm-img-contt {
    width: 18%;
    height: 73%;
}

.location-img-contt {
    width: 18%;
    height: 73%;
}

.gm-content {
    width: 65%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 70%;

}

.gm-head {
    font-family: Reddit Sans;
    font-size: 32px;
    font-weight: 700;
    color: #032168;
}

.gm-text {
    font-family: Reddit Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 25.98px;
    text-align: justify;
    color: #000000;
}

.gm-contt-two {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.cp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #153C96;
    /* Background color of the main container */
    padding: 20px;
    height: 1086px;
}

.cp-section-one {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #1343B3;
    /* Background color of each section */
    border-radius: 10px;

    margin: 25px;
    /* padding: 20px; */
    color: white;
    height: 473px;
    justify-content: start;
    gap: 5%;
}

.cp-section-two {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #1343B3;
    /* Background color of each section */
    border-radius: 10px;

    margin: 25px;
    /* padding: 20px; */
    color: white;
    height: 473px;
    justify-content: end;
    gap: 5%;
}



.cp-image-placeholder-two {
    width: 473px;
    height: 100%;
    background-color: #cccccc;
    /* Placeholder for image */
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.cp-image-placeholder-one {
    width: 473px;
    height: 100%;
    background-color: #cccccc;
    /* Placeholder for image */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.cp-text-content {
    width: 64%;
}

.cp-titlee-one {
    font-size: 32px;
    font-weight: 700;
    padding-left: 30px;
    color: #ffffff
}

.cp-titlee-two {
    font-size: 32px;
    font-weight: 700;
    display: flex;
    justify-content: end;
    padding-right: 30px;
    color: #ffffff
}

.cp-paragraph {
    font-size: 20px;
    line-height: 1.6;
    font-weight: 400;
    text-align: justify;
    margin-top: 50px;
    padding-right: 30px;
    padding-left: 30px;
}

.ap-img {
    height: 100%;
    width: 100%;
}

.weather-climate-sub-container {
    display: flex;
    justify-content: space-evenly;

}

.wc-image-container {
    width: 20%;
    height: 262px;
}

.wc-text-container {
    width: 60%;
    text-align: justify;
}

.weather-climate-container {
    height: 715px;
    background-image: url("../../../../assets/images/w-c-bg.jpg");

}

.w-c-heading {
    font-family: Reddit Sans;
    font-size: 32px;
    font-weight: 700;
    color: #032168;
    padding: 2.2%;
}

.wc-text {
    font-family: Reddit Sans;
    font-size: 20px;
    font-weight: 400;
    text-align: justify;
    color: #000000;
}

.wc-text-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.rg-sub-container {
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    height: 80%;
}

.rg-img-container {
    width: 15%;
}

.rg-container {
    display: flex;
    justify-content: space-evenly;
    height: 500px;
    align-items: center;
}

.rg-list {
    font-family: Reddit Sans;
    font-size: 20px;
    font-weight: 600;
    color: #000000;

}

.rg-text {
    font-family: Reddit Sans;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
}

.rg-heading {
    font-family: Reddit Sans;
    font-size: 32px;
    font-weight: 700;
    color: #032168;
}

.dlfg-sub-container {
    display: flex;
    gap: 40px;
}

.dlfg-container {
    height: 285px;
    background-color: #F7F9FD;
    padding: 2.5%;
    display: flex;
    flex-direction: column;
    gap: 30px;

}

.dlfg-heading {
    font-family: Reddit Sans;
    font-size: 32px;
    font-weight: 700;
    color: #032168;
}

.dlfg-text-one {
    font-family: Reddit Sans;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}

.dlfg-text-two {
    font-family: Reddit Sans;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
}

.dlfg-text-container {
    height: 80%;
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}