.mob-gme-container {
    margin-top: 80px;
}


.mob-gme-sub-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 597px;
    margin-top: 100px;

}

.mob-gme-first-container {
    height: 188px;
    border-radius: 10px;
    width: 90%;
    background-image: url("../../../../../assets/images/mob-gme-img.jpg");
    background-size: cover;
}

.mob-gme-second-container {
    height: 417px;
    border-radius: 24px;
    position: absolute;
    width: 90%;
    top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #D5D5D5;
}

.mgfc-one {
    height: 34px;
    width: 151px;
    border-radius: 32px;
    background-color: #E2B11A;
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 700;
    color: #020202;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-left: 25px;
}

.mgfc-two {
    font-family: "Reddit Sans";
    font-size: 24px;
    font-weight: 700;
    color: #FFFFFF;
    margin-top: 10px;
    width: 70%;
    margin-left: 25px;
}

.mgsc-one {
    height: 97px;
    width: 93%;
    border-radius: 12px;
    margin-top: 12px;
    background-image: url("../../../../../assets/images/mob-gme-ing-two.jpg");
    background-size: cover;
}


.mgsc-two {
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 400;
    color: #404040;
    width: 93%;
    margin-top: 20px;
    text-align: justify;
}

.mgsc-three {
    margin-top: 20px;
    border-top: 1px solid #d5d5d5;
    width: 93%;
}

.mgscf-span {
    font-family: "Reddit Sans";
    font-size: 16px;
    font-weight: 600;
    color: #464646;

}

.mgsc-four {
    margin: 15px;
    display: flex;
    justify-content: space-between;
    width: 93%;
}

.mob-gme-sub-container-second {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
    align-items: center;
}

.mgscs-two {
    font-family: "Reddit Sans";
    font-size: 24px;
    font-weight: 700;
    color: #052F91;
}

.mgscs-three {
    font-family: "Reddit Sans";
    font-size: 16px;
    font-weight: 600;
    color: #464646;
}


.gold-exploration-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    margin: 20px;
    padding: 20px;
}

.section {
    margin-bottom: 20px;
}

.section-title {
    margin-bottom: 10px;
    margin-left: -10px;
    font-family: "Reddit Sans";
    font-size: 16px;
    font-weight: 600;
    color: #040404;
}

.section-list {
    list-style-type: none;
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 400;
    color: #464646;

}

.section-item {
    margin-bottom: 5px;
    padding-left: 1.5em;
    position: relative;
    text-align: justify;
}

.section-item::before {
    content: "•";
    position: absolute;
    left: 0;
    font-weight: bold;
}


.mob-gmo-container {
    height: 780px;
    margin-top: 30px;
    border-radius: 24px;
    background-image: url("../../../../../assets//images/mob-gmo-bg.jpg");
    background-size: cover;
    margin: 20px;
    padding: 20px;
}

.mob-mgc-text {
    font-family: "Reddit Sans";
    font-size: 24px;
    font-weight: 700;
    color: #FFFFFF;
    text-align: center;
    margin-top: 10px;
}


.mob-mgc-text-two{
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 325px;
    text-align: justify;
}

.mob-card-top-one {
    height: 274px;
    width: 90%;
    background-image: url("../../../../../assets/images/cd-img.jpg");
    border-radius: 32px;
}


.mob-card-top-two {
    height: 274px;
    width: 90%;
    background-image: url("../../../../../assets/images/rpc-img.jpg");
    border-radius: 32px;
    background-size: contain;
    background-repeat: no-repeat;
}

.mob-card-bottom {
    height: 241px;
    background-color: #E2B11A;
    border-radius: 21px;
    width: 91%;
    position: absolute;
    bottom: -175px;
    padding: 20px;
}

.mob-gmo-first-card {
    /* width: 35%; */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mob-gmo-card-container {
    display: flex;
    flex-direction: column;
    gap: 200px;
    margin-bottom: 200px;
    margin-top: 60px;
}

.mob-cb-head{
    font-family: Reddit Sans;
    font-size: 20px;
    font-weight: 700;
    color: #000000;
}

.mob-cb-text{
    font-family: Reddit Sans;
    font-size: 17px;
    font-weight: 400;
    color: #000000;
    margin-top: 10px;
    text-align: justify;
}

.mob-cb-span{
    font-weight: 600;
}