.ggm_mobile_container{
    margin-top: 80px;
    padding: 20px;
    --primary: white;
    --secondary: black;
    --custom1: #262424;
    --custom2: #e2b11a;
    --custom3: #fff7ed;
    --custom4: #f5f9ff;
    --custom5: #093482;
}

h1{
    font-family: 'Reddit Sans';
    font-size: 40px;
    font-weight: 700;
    line-height: 51.95px;
    text-underline-position: ' from-font';
    text-decoration-skip-ink: none;
    color: var(--custom1);
}
h2{
    font-family: 'Reddit Sans';
    font-size: 32px;
    font-weight: 600;
    line-height: 41.56px;
    text-align: left;
    color: black;
    margin-bottom: 20px;
}
h3{
    font-family: 'Reddit Sans';
    font-size: 24px;
    font-weight: 600;
    line-height: 35.56px;
    color: var(--primary);
    margin-bottom: 20px;
}
h4{
    font-family: 'Reddit Sans';
    font-size: 20px;
    font-weight: 600;
    line-height: 35.56px;
    color: var(--custom2);
    margin: 10px 0px;
}
h5{
    font-family: 'Reddit Sans';
    font-size: 16px;
    font-weight: 600;
    color: var(--custom1);
    margin: 10px 0px;
}
p{
    font-family: 'Reddit Sans';
    font-size: 20px;
    font-weight: 400;
    line-height: 25.98px;
    text-underline-position: 'from-font';
    text-decoration-skip-ink: none;
    color: var(--custom1);
}

.ggm_hero_section{
    background-image: url('../../../../../assets/images/goldmarket.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    margin-top: 20px;
    padding-bottom: 40px;
}
.ggm_hero_text p{
    margin: 20px;
}
.ggm_image img{
    mix-blend-mode: darken;
}

/* ggm intro text container css */

.ggm_intro_container {
    background-color: #093482;
    padding: 20px;
    border-radius: 30px;
}
.ggm_intro_container .ggm_intro_text{
    border-bottom: 1px solid var(--custom2);
    padding-bottom: 20px;
}
.ggm_intro_container .ggm_intro_text h2, .ggm_intro_container .ggm_intro_text p{
    color: white;
}

.ggm_main_text h3 {
    margin-top: 20px;
}

.ggm_intro_body {
    text-align: justify;
}

.ggm_Info_icons{
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--custom2);
    padding: 10px;
    border-radius: 50%;
}

.ggm_main_sub_text_container .ggm_info{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 40px;
}
.ggm_main_sub_text_container .ggm_info p{
    color: white;
}

/* geo political section starts here  */

.geo_political_container, .geo_inflation_container{
    background-color: var(--custom3);
    padding: 20px;
    margin-top: 40px;
    border-radius: 20px;
}

.geo_political_container p {
    text-align: justify;
}

.geo_inflation_container{
    background-color: var(--custom4);
}

.geo_inflation_container p {
    text-align: justify;
}


/* economin conditions css starts here  */

.economic_conditions_container {
    margin-top: 40px;
    padding: 20px;
}

.economic_conditions_container h2{
    border-bottom: 1px solid rgb(210, 210, 210);
    padding-bottom: 20px;
    color: var(--custom5);
}
.economic_conditions_container .economic_body_text{
    display: flex;
    justify-content: space-between;
}
.economic_conditions_container .economic_body_text1 h4{
    color: var(--custom1);
    font-size: 16px;
}
.economic_conditions_container .economic_body_text2 h4{
    color: var(--primary);
    font-size: 16px;
}
.economic_conditions_container .economic_body_text1, .economic_conditions_container .economic_body_text2{
    background-color: var(--custom2);
    padding: 0px 20px;
    border-radius: 10px;
}
.economic_conditions_container .economic_body_text2{
    background-color: var(--custom5);
}
.economic_body_text .vs{
    color: gray;
    font-weight: 600;
}
.economic_main_text {
    margin-top: 20px;
}

.economic_main_text p {
    text-align: justify;
}

.economic_graph_text1, .economic_graph_text2{
    margin-top: 20px;
}
.economic_graph_text1{
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(199, 199, 199);
    margin-bottom: 40px;
}
.economic_graph_text1 img, .economic_graph_text2 img{
    margin-bottom: 20px;
}
.economic_graph_text1 h3, .economic_graph_text2 h3{
    color: var(--custom1);
    text-align: center;
}

/* investment demand container css starts here  */

.investment_demand_container{
    position: relative;
    text-align: center;
}

.investment_demand_container .investment_demand_text{
    position: absolute;
    top:0;
    padding: 20px;
}
.investment_demand_container .investment_demand_text h2, .investment_demand_container .investment_demand_text h5{
    color: var(--primary);
    margin-bottom: 10px;
}


/* gold global market sections starts here  */


.gold_global_market_container{
    margin-top: 40px;
    padding: 20px 20px 40px 20px;
    border-bottom: 2px solid rgb(203, 203, 203);
}
.gold_global_market_container ul{
    padding: 0px 20px 40px;
}
.gold_global_market_container h3{
    color: var(--custom5);
}
.gold_global_market_container p{
    font-size: 16px;
}


/* cfd dynamic chars sections starts here  */

.cfd-container {
    width: 100%;
    height: 645px;
    background-color: beige;
    border-radius: 24px;
    margin-top: 2rem !important;
}


/* gold global market country sections starts here  */

.gold_producing_company_container{
    margin-top: 40px;
}
.gold_producing_company_bg{
    padding: 0px 20px 40px 20px;
    background-image: url('../../../../../assets/images/global_map.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


/* future outlook sections css starts here */

.future_outlook_container{
    margin-top: 40px;
    background-color: var(--custom5);
    padding: 20px;
    border-radius: 20px;
}
.future_outlook_container h2, .future_outlook_container p{
    color: white;
}
.future_outlook_container_content p{
    color: white !important;
}
.future_outlook_cards{
    display: flex;
    gap:20px;
    align-items: flex-start;
}


/* gold reserve worldwide sections css starts here  */

.gold_reserve_worldwide_container{
    margin-top: 40px;
}
.gold_reserve_worldwide_container h2{
    color: var(--custom5);
}
.gold_reserve_worldwide_container span{
    font-weight: 600;
}
.gold_reserve_worldwide_container p{
    text-align: left;
}