.gme-first-container {
    height: 590px;
    padding: 10px;
    position: relative;
    margin: 90px;
    margin-top: 120px;
    border-radius: 40px;
    background-image: url('../../../../../assets/images/gme-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.gmefc-two {
    position: absolute;
    width: 657px;
    height: 400px;
    background-color: #ffffff;
    right: 0px;
    bottom: -100px;
    z-index: 100 !important;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: .5px solid rgb(231, 229, 229);
}

.gfc-head-container {
    padding: 60px;
    display: flex;
    flex-direction: column;
}

.gfc-main-heading {
    width: 224px;
    height: 47px;
    font-family: Reddit Sans;
    font-size: 24px;
    font-weight: 700;
    color: #020202;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    background-color: #E2B11A;
}




.gfc-sub-heading {
    font-family: Reddit Sans;
    font-size: 54px;
    font-weight: 700;
    color: #ffffff;
}

.gto-bg {
    background-image: url("../../../../../assets/images/gme-bg-two.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 142px;
}

.gto-text {
    font-family: Reddit Sans;
    font-size: 16px;
    font-weight: 400;
    color: #404040;
    margin-top: 10px;
}

.gt-one {
    margin: 20px;
}


.gt-two {
    margin: 0px 30px 5px 30px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid grey;
}

.gtt-text {
    font-family: Reddit Sans;
    font-size: 20px;
    font-weight: 600;
    color: #464646;

}

.gsc-head {
    height: 47px;
    width: 218px;
    background-color: #E2B11A;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #020202;
    font-family: Reddit Sans;
    font-size: 24px;
    font-weight: 700;
}

.gsc-two {
    font-family: Reddit Sans;
    font-size: 32px;
    font-weight: 700;
    color: #052F91;
}

.gsc-three {
    font-family: Reddit Sans;
    font-size: 20px;
    font-weight: 600;
    color: #464646;
}

.gme-second-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 160px;
}

.marqueeee {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
}

.marqueeee div {
    animation: scroll 15s linear infinite;
}

.mq-sccc {
    display: flex;
    gap: 20px;
    width: 100%;
    margin-top: 20px;
}

.marquee-containerr {
    width: 100%;
}

.table-container {
    display: flex;
    gap: 80px;
}

.tc-title {
    width: 20%;
    font-family: Reddit Sans;
    font-size: 20px;
    font-weight: 600;
    color: #040404;

}

.main-contt {
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 18%;
    margin-top: 50px;
}

.table-list-item {
    font-family: Reddit Sans;
    font-size: 20px;
    font-weight: 400;
    color: #464646;

}

.gmo-main-contt {
    height: 500px;
    background-color: #153C97;
    margin-top: 50px;
    border-radius: 32px;
    margin: 40px;
    display: flex;
    justify-content: space-evenly;
    
}


.gmc-contt-one {
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
    margin-top: 40px;
    margin-left: 100px;

}

.gmc-contt-two {
    width: 40%;
    background-color: #ffffff;
    margin: 40px;
    background-image: url("../../../../../assets/images/gmo-bg-new.jpg");
    background-size: cover;
    border-radius: 40px;
}

.gmo-text {
    font-family: Reddit Sans;
    font-size: 32px;
    font-weight: 700;
    color: #ffffff;

}

.gmo-sub-text {
    font-family: Reddit Sans;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    width: 70%;
    text-align: justify;

}

.card-top-one {
    height: 371px;
    width: 90%;
    background-image: url("../../../../../assets/images/cd-img.jpg");
    border-radius: 32px;
}

.card-top-two {
    height: 371px;
    width: 90%;
    background-image: url("../../../../../assets/images/rpc-img.jpg");
    border-radius: 32px;
    background-size: cover;
}

.card-bottom {
    height: 241px;
    background-color: #E2B11A;
    border-radius: 32px;
    width: 100%;
    position: absolute;
    bottom: -185px;
padding: 20px;
}

.gmo-first-card {
    width: 35%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gmo-card-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 200px;
    margin-top: 60px;
}

.cb-head{
    font-family: Reddit Sans;
    font-size: 32px;
    font-weight: 700;
    color: #000000;
}

.cb-text{
    font-family: Reddit Sans;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    margin-top: 10px;
    text-align: justify;
}

.cb-span{
    font-weight: 600;
}