.mpos-head {
    font-family: "Reddit Sans";
    font-size: 24px;
    font-weight: 700;
    color: #032168;
}

.mpos-text {
    font-family: "Reddit Sans";
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin-top: 15px;

}

.mpos-card-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
}

.mpos-card {
    height: 123px;
    background-image: linear-gradient(90deg, #CD9E0B, #FFCD31);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px;
}

.mob-pillars-of-success {
    display: flex;
    flex-direction: column;
    margin-top: 15%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 10%;
}

.mpos-card-head {
    font-family: Reddit Sans;
    font-size: 16px;
    font-weight: 700;
    color: #000000;

}

.mpos-card-text {
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}

.mob-okm-container {
    height: 395px;
    background-color: #032168;
    padding-top: 7%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-left: 10%;
}

.mob-okm-head {
    font-family: Reddit Sans;
    font-size: 24px;
    font-weight: 700;
    color: #FFFFFF;
}

.mob-okm-card {
    height: 40%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mob-okm-card-container {
    display: flex;
}

.mokmc-head {
    font-family: "Reddit Sans";
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;

}

.mokmc-text-one {
    font-family: "Reddit Sans";
    font-size: 64px;
    font-weight: 700;
    color: #FFFFFF;

}

.mokmc-text-two {
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
}

.mob-mining-container {
    height: 529px;
    background-image: url("../../../../assets/images/mob-mining.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 30px;
}

.mmc-sub-container {
    padding-top: 365px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mining-mob-head {
    font-family: "Reddit Sans";
    font-size: 24px;
    font-weight: 700;
    color: #032168;
    text-align: center;
}

.mob-mining-text {
    font-family: Reddit Sans;
    font-size: 14px;
    font-weight: 400;
    color: #232323;
    text-align: justify;
    margin-top: 15px;
    margin-left: 5%;
    margin-right: 5%;
}

.mmc-btn {
    height: 42px;
    width: 177px;
    background-image: linear-gradient(90deg, #E7CD12, #C48E08);
    border-radius: 8px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: #FFFFFF;
}

.mcp-btn {
    height: 42px;
    width: 197px;
    background-image: linear-gradient(90deg, #E7CD12, #C48E08);
    border-radius: 8px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: #FFFFFF;
}



.mcp-head {
    font-family: "Reddit Sans";
    font-size: 24px;
    font-weight: 700;
    color: #032168;
    text-align: center;
}

.mcp-text-one {
    font-family: " Reddit Sans";
    font-size: 16px;
    font-weight: 500;
    color: #545454;
    text-align: justify;
    margin-left: 5%;
    margin-right: 5%;
}

.mob-cp-container {
    height: 730px;
    background-image: url("../../../../assets/images/mob-cp-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 40px;
    padding-top: 30px;
    align-items: center;
}

.mob-ocv-container {
    height: 828px;
    background-color: #F3F6FA;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 20px;
    margin-top: 30px;
    align-items: center;
}

.mcpc-head {
    font-family: Reddit Sans;
    font-size: 24px;
    font-weight: 700;
    color: #032168;
    text-align: center;
}

.m-card-b {
    height: 117px;
    width: 95%;
    background-image: linear-gradient(90deg, #093280, #4C6BB1);
    border-radius: 12px;
    padding: 16px;
}

.m-card-y {
    height: 117px;
    width: 95%;
    background-image: linear-gradient(90deg, #CC9C0B, #E3B21B);
    border-radius: 12px;
    padding: 16px;
}

.mcpc-card-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 17px;
    align-items: center;
}

.mc-text-head {
    font-family: "Reddit Sans";
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
}


.mc-text {
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 8px;
}


.mob-car-container {
    margin-top: 80px;
    height: 775px;
    position: relative;

}

.mob-carousel-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px;
    margin-top: 30px;
}


.mob-carousel-content {
    position: absolute;
    top: 0px;
}

.mcc-four {
    height: 42px;
    width: 197px;
    background-image: linear-gradient(90deg, #E7CD12, #C48E08);
    border-radius: 8px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: #FFFFFF;
}

.mcc-one {
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 700;
    color: #0A3584;
}

.mcc-two {
    font-family: "Reddit Sans";
    font-size: 24px;
    font-weight: 700;
    color: black;
}

.mcc-three {
    font-family: "Reddit Sans";
    font-size: 16px;
    font-weight: 500;
    color: #222222;
}