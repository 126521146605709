.cu-first-container {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 160px;
    gap: 10px;
}

.cu-heading {
    width: 291px;
    height: 62px;
    /* font-family: "Reddit Sans"; */
    font-size: 48px;
    font-weight: 700;
    color: #032168;
}

.cuh-text {
    width: 35%;
    /* font-family: Reddit Sans; */
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #323232;
}

.map-form-container {
    margin-top: 25px;
}

.map-form-container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
}

.map-contt {
    border-radius: 16px;
    height: 654px;
    width: 40%;
    background-image: url("../../../../assets/images/map-image.jpg");
    background-repeat: no-repeat;
    margin-bottom: 30px;
}

.form-contt {
    border: 1px solid #EAEAEA;
    border-radius: 16px;
    background-color: #FAFAFA;
    width: 28%;
    height: 854px;
}

.cu-card-container {
    height: 500px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-evenly;
    /* margin-right: 15%;
    margin-left: 15%; */
    align-items: center;
    margin: 5px;
}

.cu-card {
    width: 312px;
    height: 312px;
    border-radius: 24px;
    background-image: url("../../../../assets/images/cu-card-bg.jpg");    
    padding: 6.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.cu-card-first-container {
    height: 38px;
    width: 38px;
    border-radius: 12px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cu-card-second-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cu-card-text-one {
    /* font-family: Reddit Sans; */
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;

}

.cu-card-text-two {
    /* font-family: Reddit Sans; */
    font-size: 16px;
    font-weight: 400;
    color: #C7C4C4;
}

.cu-card-text-three {
    height: 39px;
    border-radius: 10px;
    background-color: #FFFFFF;
    font-family: Reddit Sans;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    padding-left: 10px;
    display: flex;
    align-items: center;
    width: 78%;

}

.map-card {
    width: 50%;
    background-color: #FFFFFF;
    border-radius: 24px;
    height: 113px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    margin-left: 3%;
    margin-top: 55%;
}


.form-container {
    padding: 20px;
}

form {
    display: flex;
    flex-direction: column;
}

label {
    font-weight: bold;
    margin-top: 15px;
    font-size: 14px;
    margin-top: 25px;
}

input,
textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

input::placeholder,
textarea::placeholder {
    color: #c0c0c0;
}

textarea {
    resize: none;
    height: 120px;
}

.subject-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 5px;
}

.subject-button {
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    background-color: white;
    color: grey;
    cursor: pointer;
}

.subject-button.selected {
    background-color: #292929;
    color: white;
    border: none;
}

.subject-button:hover {
    opacity: 0.8;
}

.submit-button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    background-color: #E7CD12;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 50%;
}

.submit-button:hover {
    background-color: #e0a800;
}


.map-card-text {
    font-family: Reddit Sans;
    font-size: 16px;
    font-weight: 500;
    color: #363636;
}

.map-text-container{
margin-top: 5px;
}

.logo-image-container{
 width: 90px;
height: 60px;
}

.logo-image-gds{
height: 100%;
width: 100%;
object-fit: contain;
}