.mob-gallery-container {
    margin-top: 100px;
}

.mgc-first-contt {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;
}

.img-marq-mobile {
    height: 200px;
    width: 200px;
}

.mm-cont {
    margin-top: 30px;
}

.mobile-km {
    font-family: "Reddit Sans";
    font-size: 24px;
    font-weight: 700;
    color: #032168;
    text-align: center;
}

.mob-km-text {
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 500;
    color: #323232;
    text-align: center;
    width: 90%;
}

.images-container-mobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-top: 30px;
}

.icm-image {
    height: 300px;
    width: 80%;
}