.blue-div {
    width: 1320px;
    height: 523px;
    top: 762px;
    left: 60px;
    gap: 0px;
    border-radius: 32px;
    background: #093482;

}

.blue-small-text {
    font-family: 'Reddit Sans';
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: justify;
    text-underline-position: 'from-font';
    text-decoration-skip-ink: none;
    color: #FFFFFF;

}

.blue-heading {
    font-family: 'Reddit Sans';
    font-size: 32px;
    font-weight: 600;
    line-height: 41.56px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FFFFFF;

}

.blue-first-container {
    padding: 28px;
    border-bottom: 1px solid #E2B11A
}

.blue-second-heading {
    font-family: 'Reddit Sans';
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: 'from-font';
    text-decoration-skip-ink: none;
    color: #FFFFFF;
    padding: 27px;


}

.mining-company-text {
    color: #E2B11A;
    font-family: 'Reddit Sans';
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-top: 6px;

}

.yellow-subtext {
    font-family: 'Reddit Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 20.78px;
    letter-spacing: 0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: white;

}

.yellow-img {
    width: 81px;
    height: 81px;
    background: #E2B11A;
    border-radius: 50px;
    display: flex;
    justify-content: center;
}