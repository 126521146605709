.mobile-contact-main-container {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.map-mobile-background {
    background-image: url("../../../../assets/images/map-new--image-mob.jpg");
    width: 90%;
    height: 308px;
    border-radius: 8px;
    background-size: cover;
    margin-top: 20px;
}

.cu-mob {
    font-family: "Reddit Sans";
    font-size: 24px;
    font-weight: 700;
    color: #032168;
    text-align: center;
}

.wwyf-mob {
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 500;
    color: #323232;
    text-align: center;
    width: 80%;
}

.mcmc-first-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
}