.cp-mobile {
    height: 80px;
    background-image: url("../../../../assets/images_backup/cp-frame.svg");
    margin-top: 100px;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Reddit Sans";
    font-size: 24px;
    font-weight: 700;
    color: #032168;
}

.gm-mob-container {
    height: 710px;
    background-color: #F7F9FD;
}

.gm-mob-image-container {
    height: 345px;
    padding: 10px;
}

.gm-mob-image {
    height: 100%;
    width: 100%;
}


.gm-mob-text-container {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 35px;
    margin-bottom: 35px;
}

.gm-mob-text-one {
    font-family: "Reddit Sans";
    font-size: 24px;
    font-weight: 700;
    color: #032168;
    margin-bottom: 15px;
}

.gm-mob-text-two {
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: justify;
}

.gm-mob-container-new {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 5%;
    margin-right: 5%;
    /* height: 910px; */
}

.ap-mobile-container {
    background-color: #153C96;
    height: 1220px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.ap-mob-card-contt {
    height: 48%;
    border-radius: 16px;
    background-color: #1B4DC0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
}

.apcm-image {
    width: 100%;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    height: 100%;
}

.apcmt-head {
    font-family: Reddit Sans;
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
}

.apcmt-body {
    font-family: Reddit Sans;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    text-align: justify;
}

.ap-card-m-image-container {
    height: 212px;
}

.ap-card-m-text-container {
    height: 950px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
}

.wc-mob-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-image: url("../../../../assets/images/wc-mob-bg-image.png");
    background-size: cover;

}

.wc-contt-mob-two {
    width: 100%;
}

.wc-contt-mob-one {
    font-family: "Reddit Sans";
    font-size: 24px;
    font-weight: 700;
    color: #032168;
    height: 50px;
    text-align: center;
    margin-top: 40px;
}

.wcmt-img {
    width: 100%;
}

.wc-contt-mob-three {
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    text-align: justify;
}

.rg-mob-container {
    /* margin-top: 50px; */
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 5%;
    margin-right: 5%;
}

.rgm-head {
    font-family: "Reddit Sans";
    font-size: 24px;
    font-weight: 700;
    color: #032168;
    text-align: center;
}

.rgm-img {
    height: 425px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.rgmi {
    width: 96%;
}

.rgm-text-one {
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-top: 20px;
    text-align: justify;
}

.rgm-text-two {
    font-family: "Reddit Sans";
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
    width: 94%;
    margin-left: 5%;
    margin-right: 5%;
}

.dlfg-container-new {
    height: 415px;
    background-color: #F7F9FD;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 60px;
}

.dlfg-head {
    font-family: Reddit Sans;
    font-size: 24px;
    font-weight: 700;
    color: #032168;
    width: 80%;
    text-align: center;
}

.dlfg-text-container-new{
    margin-top: 30px;
}

.dlfg-text-one-new{
    font-family: "Reddit Sans";
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    width: 100%;
    padding-left: 5%;
    padding-bottom: 5%;
}

.dlfg-text-two-new{
    font-family: "Reddit Sans";
    font-size: 15px;
    font-weight: 400;
    color: #000000;
    width: 100%;
    text-align: justify;
    padding-left: 5%;
    padding-right: 5%;
}

.dlfg-text-container-new{
    width: 100%;
}