body {
    margin: 0px !important;
}

.header-container {
    display: flex;
    height: 80px;
    justify-content: space-between;
    background-image: linear-gradient(90deg, #00185C, #1458B5);
    color: white;
    padding-right: 6%;
    position: fixed;
    top: 0;
    z-index: 111111111;
    width: 100%;
}

.navigator {
    font: Reddit Sans;
    font-weight: 500;
    color: #ffffff;
    font-size: 16px;
    text-decoration: none;
}

.navigator:hover {
    color: #ffffff;
    text-decoration: none;
}

.navigation-container {
    display: flex;
    width: 45%;
    justify-content: space-around;
    height: 100%;
    align-items: center;
}

.first-container {
    display: flex;
    align-items: center;
    width: 12%;
    justify-content: space-between;
}

.design-div {
    height: 100%;
    width: 80%;
    object-fit: contain;
}

.selected-navigator {
    color: #FFC400 !important;
    border-bottom: 2px solid #FFC400 !important;
    height: 35%;
    /* margin-top: 1%; */
}

.header-logo {
    cursor: pointer;
}
  

.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100% !important;
  right: 0 !important;
left: -75px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
margin-right: 100px ;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  width: 200px;  

}

.dropdown-item {
  padding: 8px 16px;
  text-decoration: none;
  color: #333;
  white-space: nowrap;

}

.dropdown-item:hover {
  background-color: #f0f0f0;

}

.dropdown-trigger {
  display: inline-block;
}